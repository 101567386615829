<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.fullName || activeUserInfo.companyName">
    <div class="text-right leading-tight hidden sm:block">
      <h4 class="font-normal text-xs">{{ activeUserInfo.fullName || activeUserInfo.companyName }}</h4>
    </div>
    <div class="pp-seperator hidden sm:block"></div>
    <div class="show-in-large-screen hidden md:block flex justify-center">
      <div @click="activeUserInfo.type === 'user' ? $router.push({ name: 'admin-view-profile' }): ''" class="mb-1">
        <S3ImageGenerator
          v-if="activeUserInfo.photoURL && activeUserInfo.photoURL !='/img/default.37f922ae.png'"
          :is-base64="false"
          :document="activeUserInfo.profileImage ? activeUserInfo.profileImage : activeUserInfo.profileImage"
          :customClass="'profile-image-circle-nav'"
          :key="activeUserInfo.profileImage ? activeUserInfo.profileImage : activeUserInfo.profileImage"
          class="rounded-full shadow-md block cursor-pointer"
        />
        <vs-avatar v-else class="font-medium text-base alpha-avatar cursor-pointer rounded-full shadow-md bg-white m-0" :text="generateFullName()"/>
      </div>
      <div class="text-center cursor-pointer leading-none" @click="logout">
        <span class="text-body-gray underline text-xxs whitespace-nowrap">Log out</span>
      </div>
    </div>
    <vs-dropdown class="cursor-pointer md:hidden" vs-trigger-click>
      <div class="con-img">
        <S3ImageGenerator
          v-if="activeUserInfo.photoURL && activeUserInfo.photoURL !='/img/default.37f922ae.png'"
          :is-base64="false"
          :document="activeUserInfo.profileImage ? activeUserInfo.profileImage : activeUserInfo.profileImage"
          :customClass="'profile-image-circle-nav'"
          :key="activeUserInfo.profileImage ? activeUserInfo.profileImage : activeUserInfo.profileImage"
          class="rounded-full shadow-md block cursor-pointer"
        />
        <vs-avatar v-else class="font-medium text-base alpha-avatar cursor-pointer rounded-full shadow-md bg-white m-0" :text="generateFullName()"/>
      </div>
      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push({ name: 'admin-view-profile' }).catch(() => {})">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push({ name: 'settings' })">
            <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Settings</span>
          </li>
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push({ name: 'partner-help' })">
            <feather-icon icon="HelpCircleIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Help</span>
          </li>
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import { clearLocalStorage } from "../../../../helper/localStorage.js";
import { EventBus } from "../../../../mixins/event-bus";
import { mapActions } from "vuex";
import S3ImageGenerator from "../../../../views/components/S3ImageGenerator";

export default {
  components: {
    S3ImageGenerator
  },

  data() {
    return {
      leavePopUpAction: "default",
    };
  },

  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },

  methods: {
    ...mapActions("admin", ["logoutAdmin"]),

    removeToken() {
      if ( localStorage.getItem("accessToken")
        && typeof localStorage.getItem("redirectTo") === "string"
          && ( localStorage.getItem("redirectTo") === "undefined" ||
          localStorage.getItem("redirectTo") === "partner-login"
        )
      ) {
        $cookies.remove("rememberMe");
        $cookies.remove("rememberMeToken");
        $cookies.remove("password");
        clearLocalStorage();
      }
    },

    logout() {
      this.leavePopUpAction = "default";
      if ( localStorage.getItem("formFieldUpdated") && localStorage.getItem("formFieldUpdated") === 'no') {
        this.removeToken();
        this.$router.push("/login").catch(() => {});
      } else {
        this.$router.push("/login").catch(() => {});
      }
    },

    generateFullName(fullName) {
      if (!"fullName" in this.activeUserInfo || !this.activeUserInfo.fullName) {
        return "";
      }

      return this.activeUserInfo.fullName.split(' ').map((item) => item.charAt(0)).join('').toUpperCase()
    }
  },

  created() {
    this.leavePopUpAction = "default";
    EventBus.$on("leavePopUpTrigger", action => {
      this.leavePopUpAction = action;
    });
  },

  watch: {
    leavePopUpAction: {
      async handler(updatedValue, previousValue) {
        if (updatedValue && updatedValue === 'yes') {
          this.removeToken();
        }
      },
      flush: "post",
      immediate: true
    }
  }
};
</script>
