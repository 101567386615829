let adminMenu = [
  {
    url: "/admin/dashboard",
    name: "Home",
    slug: "dashboard",
    icon: "HomeIcon",
    i18n: "Dashboard"
  },
  {
    url: "/admin/charity-partners",
    name: "Charity Merchants",
    slug: "charity-partners",
    icon: "PeopleIcon",
    i18n: "Charity Merchants"
  },
  {
    url: "/admin/fundraiser",
    name: "Fundraisers",
    slug: "fundraisers",
    icon: "PeopleIcon",
    i18n: "Fundraisers"
  },
  {
    url: "/admin/cms-pages",
    name: "Page Management",
    slug: "page-management",
    icon: "FileIcon",
    i18n: "Page Management"
  },
  {
    url: "/admin/card-management",
    name: "Card Management",
    slug: "card-management",
    icon: "FileIcon",
    i18n: "Card Management"
  }
];

let partnerAdminMenu = [
  {
    url: "/dashboard",
    name: "partner-dashboard",
    slug: "dashboard",
    icon: "HomeIcon",
    i18n: "Home"
  },
  {
    url: "/settings",
    name: "partner-pages",
    slug: "Settings",
    icon: "PageIcon",
    i18n: "Settings"
  },
  {
    url: "/help",
    name: "partner-help",
    slug: "help",
    icon: "HelpCircleIcon",
    i18n: "Help"
  },
];

let partnerStaffMenu = [
  {
    url: "/dashboard",
    name: "partner-dashboard",
    slug: "dashboard",
    icon: "HomeIcon",
    i18n: "Home"
  },
  {
    url: "/payment-requests",
    name: "staff-payment-requests",
    slug: "payment-requests",
    icon: "CreditCardIcon",
    i18n: "Payment Activity"
  },
  {
    url: "/help",
    name: "partner-help",
    slug: "help",
    icon: "HelpCircleIcon",
    i18n: "Help"
  }
];

export { adminMenu, partnerAdminMenu, partnerStaffMenu };
