<template>
  <div class="relative">
    <div class="vx-navbar-wrapper" :class="classObj">
      <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">
        <logo class="fill-current text-primary" />
        <vs-spacer />
        <div class="text-center flex items-center partner-navbar">
          <a v-if="loggedInUser && loggedInUser.userType !== 'staff'" @click="$router.push({ name: 'partner-dashboard' })" class="text-sm" :class="{ 'nav-link-active': $route.name === 'partner-dashboard' }">Home</a>
          <a v-if="loggedInUser && loggedInUser.userType !== 'staff'" @click="$router.push({ name: 'settings' })" class="text-sm" :class="{ 'nav-link-active': $route.name === 'settings' }">Settings</a>
          <a @click="handleHelpClick()" class="text-sm" :class="{ 'nav-link-active': $route.name === 'help' }">Help</a>
        </div>
        <profile-drop-down />
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import ProfileDropDown from "./components/ProfileDropDown.vue";
import Logo from "../Logo.vue";
export default {
  name: "the-navbar-vertical",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  components: {
    ProfileDropDown,
    Logo,
  },
  computed: {
    isFlipPay() {
      return process.env.VUE_APP_NAME.toLowerCase().includes("flippay");
    },

    loggedInUser() {
      return JSON.parse(localStorage.getItem("userInfo"));
    },
    pageTitle() {
      return this.$route.meta.pageTitle;
    },
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff"
        ? "#10163a"
        : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return {
        "text-white":
          (this.navbarColor != "#10163a" &&
            this.$store.state.theme === "dark") ||
          (this.navbarColor != "#fff" && this.$store.state.theme !== "dark"),
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth == "default") return "navbar-default";
      else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced";
      else if (this.verticalNavMenuWidth) return "navbar-full";
      else return "navbar-default";
    },
  },
  methods: {
    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    },

    handleHelpClick() {
      if (this.isFlipPay) {
        window.open("https://help.flippay.com.au", "_blank");
      } else {
        window.open("https://help.rellopay.co", "_blank");
      }
    }
  },
};
</script>

