<template>
  <div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, { 'no-scroll': isAppPage }]">
    <div id="content-area" :class="[contentAreaClass, { 'show-overlay': bodyOverlay }]">
      <div id="content-overlay" />

      <!-- Navbar -->
      <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
        <the-navbar-horizontal
          :navbarType="navbarType"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
        />

        <h-nav-menu
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
          :navMenuItems="MainMenuItems"
        />
      </template>

      <template v-else>
        <the-navbar-vertical
          :navbarColor="navbarColor"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
        />
      </template>
      <!-- /Navbar -->

      <div class="content-wrapper">
        <div class="router-view">
          <div class="router-content">
            <transition :name="routerTransition">
              <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-0 md:mb-6 lg:mb-8 mt-6">
                <div class="content-area__heading" :class="{ ' border-0 md:border-r border-solid border-grey-light': $route.meta.breadcrumb }"></div>
                <vx-breadcrumb class="md:block hidden " v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl"/>
              </div>
            </transition>
            <h2 class="text-xl md:text-2xl lg:text-3xl font-medium page-head-title mb-4 md:mb-6 lg:mb-8">{{ routeTitle }}</h2>

            <div class="content-area__content">
              <back-to-top bottom="5%" :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'" visibleoffset="500" v-if="!hideScrollToTop">
                <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top"/>
              </back-to-top>
              <transition :name="routerTransition" mode="out-in">
                <router-view @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)"/>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <the-footer />
    </div>
  </div>
</template>

<script>
import BackToTop from "vue-backtotop";
import HNavMenu from "@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue";
import { adminMenu, partnerAdminMenu, partnerStaffMenu } from "@/layouts/components/vertical-nav-menu/navMenuItems.js";
import TheNavbarHorizontal from "@/layouts/components/navbar/TheNavbarHorizontal.vue";
import TheNavbarVertical from "@/layouts/components/navbar/TheNavbarVertical.vue";
import TheFooter from "@/layouts/components/TheFooter.vue";
import themeConfig from "@/../themeConfig.js";
import { Validator } from "vee-validate";

export default {
  components: {
    BackToTop,
    HNavMenu,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
  },

  data() {
    return {
      navMenuItems: adminMenu,
      adminMenu: adminMenu,
      partnerAdminMenu: partnerAdminMenu,
      partnerStaffMenu: partnerStaffMenu,
      MainMenuItems: [],
      disableCustomizer: themeConfig.disableCustomizer,
      disableThemeTour: themeConfig.disableThemeTour,
      dynamicWatchers: {},
      footerType: themeConfig.footerType || "static",
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || "#fff",
      navbarType: themeConfig.navbarType || "floating",
      routerTransition: themeConfig.routerTransition || "none",
      routeTitle: this.$route.meta.pageTitle,
    };
  },

  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
    isThemeDark(val) {
      const color = this.navbarColor == "#fff" && val ? "#10163a" : "#fff";
      this.updateNavbarColor(color);
    },
    "$store.state.mainLayoutType"(val) {
      this.setNavMenuVisibility(val);
      this.disableThemeTour = true;
    },
  },

  computed: {
    bodyOverlay() {
      return this.$store.state.bodyOverlay;
    },

    contentAreaClass() {
      if (this.mainLayoutType === "vertical") {
        if (this.verticalNavMenuWidth == "default") {
          return "content-area-reduced";
        } else if (this.verticalNavMenuWidth == "reduced") {
          return "content-area-lg";
        } else {
          return "content-area-full";
        }
      } else {
        return "content-area-full";
      }
    },

    footerClasses() {
      return {
        "footer-hidden": this.footerType == "hidden",
        "footer-sticky": this.footerType == "sticky",
        "footer-static": this.footerType == "static",
      };
    },

    isAppPage() {
      return this.$route.meta.no_scroll;
    },

    isThemeDark() {
      return this.$store.state.theme == "dark";
    },

    layoutTypeClass() {
      return `main-${this.mainLayoutType}`;
    },

    mainLayoutType() {
      return this.$store.state.mainLayoutType;
    },

    navbarClasses() {
      return {
        "navbar-hidden": this.navbarType == "hidden",
        "navbar-sticky": this.navbarType == "sticky",
        "navbar-static": this.navbarType == "static",
        "navbar-floating": this.navbarType == "floating",
      };
    },

    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },

    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },

  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title;
    },

    updateNavbar(val) {
      if (val == "static")
        this.updateNavbarColor(this.isThemeDark ? "#10163a" : "#fff");
      this.navbarType = val;
    },

    updateNavbarColor(val) {
      this.navbarColor = val;

      if (val == "#fff") {
        this.isNavbarDark = false;
      } else {
        this.isNavbarDark = true;
      }
    },

    updateFooter(val) {
      this.footerType = val;
    },

    updateRouterTransition(val) {
      this.routerTransition = val;
    },

    setNavMenuVisibility(layoutType) {
      if ((layoutType === "horizontal" && this.windowWidth >= 1200) || (layoutType === "vertical" && this.windowWidth < 1200)) {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
        this.$store.dispatch("updateVerticalNavMenuWidth", "no-nav-menu");
      } else {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
      }
    },

    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val;
    },
  },

  created() {
    const color = this.navbarColor == "#fff" && this.isThemeDark ? "#10163a" : this.navbarColor;
    this.updateNavbarColor(color);
    this.setNavMenuVisibility(this.$store.state.mainLayoutType);

    // Dynamic Watchers for tour
    // Reason: Once tour is disabled it is not required to enable it.
    // So, watcher is required for just disabling it.
    this.dynamicWatchers.windowWidth = this.$watch(
      "$store.state.windowWidth",
      (val) => {
        if (val < 1200) {
          this.disableThemeTour = true;
          this.dynamicWatchers.windowWidth();
        }
      }
    );

    this.dynamicWatchers.verticalNavMenuWidth = this.$watch(
      "$store.state.verticalNavMenuWidth",
      () => {
        this.disableThemeTour = true;
        this.dynamicWatchers.verticalNavMenuWidth();
      }
    );

    this.dynamicWatchers.rtl = this.$watch("$vs.rtl", () => {
      this.disableThemeTour = true;
      this.dynamicWatchers.rtl();
    });

    Validator.extend("alphaNumSpace", {
      getMessage: (field) => `Invalid ${field} field`,
      validate: (value) => !value.match(/[^a-zA-Z0-9\s]/),
    });
  },

  mounted() {
    let userInfo = this.$store.state.AppActiveUser;
    let userType = userInfo.userType;

    if (userType == "admin" || userType == "staffAdmin") {
      this.MainMenuItems = partnerAdminMenu;
    } else if (userType == "staff") {
      this.MainMenuItems = partnerStaffMenu;
    } else {
      this.MainMenuItems = adminMenu;
    }

    // FP-1105 - Add hubspot live chat (when user logged in)
    if (userInfo && process.env.VUE_APP_SHOW_CHAT == "true") {
      let script = document.createElement('script');
      script.setAttribute("id", "hs-script-loader");
      script.setAttribute("src", "//js.hs-scripts.com/20855532.js");
      document.head.appendChild(script);
    }
    // TODO: NEED TO UNCOMMENT AFTER 1.24 IS RELEASED
    // if (userInfo) {
    //   window.intercomSettings = {
    //     api_base: "https://api-iam.intercom.io",
    //     app_id: "k4ih4toa",
    //     user_id: userInfo._id, // a UUID for your user
    //     email: userInfo.email || "", // a UUID for your user
    //     user_hash: userInfo && userInfo.token && userInfo.token.chatbotHash ? userInfo.token.chatbotHash : "" // an Identity Verification user hash for your user
    //   };

    //   let chabotScript = document.createElement("script");
    //   const textContent = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/k4ih4toa';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()`;
    //   chabotScript.textContent = textContent;

    //   document.head.appendChild(chabotScript);
    // }
  },

  beforeDestroy() {
    Object.keys(this.dynamicWatchers).map((i) => {
      this.dynamicWatchers[i]();
      delete this.dynamicWatchers[i];
    });
  },
};
</script>